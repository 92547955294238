import { useSelector } from 'react-redux'
import { imgs } from '../../images'
import { ProductsForClients, TicketItems } from '../../types'
import SummaryTableHead from './SummaryTableHead'
import { WhatsappIcon, WhatsappShareButton } from 'react-share'
import BaseModal from '../BaseModal/BaseModal'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import './SummaryTicketClients.css'


interface SummaryTicketClientsProps {
  setShowSummary: (showSummary: boolean) => void
}

const SummaryTicketClientsContent = ({ setShowSummary }: SummaryTicketClientsProps) => {
  const [tableText, setTableText] = useState('')
  const productsForClients: ProductsForClients = useSelector((s: any) => s.productsForClients)
  const ticket: TicketItems[] = useSelector((s: any) => s.ticket)
  const ticketWithFindins = ticket.map((product) => {
    const founded: ProductsForClients = productsForClients.filter((client) => client.products.some((p) => p.uniqueId === product.uniqueId))
    return { ...product, repeteadTimes: founded.length === 0 ? 1 : founded.length }
  })
  const [copied, setCopied] = useState(false)

  const getTextFromTable = () => {
    const tableElements = document.querySelectorAll('.summary-ticket-table')
    let tableText = ''
    if (tableElements.length) {
      tableElements.forEach((tableElement, index) => {
        const rows = tableElement.querySelectorAll('tr')
        rows.forEach((row) => {
          const clientTotal = row.querySelectorAll('th')
          const clientTotalArray = Array.from(clientTotal).map((client) => client.textContent)
          const clientText = `*${clientTotalArray[0]?.trim()} paga ${clientTotalArray[1]?.trim()}*\n`
          if (clientTotal.length) {
            tableText += `${clientText}`
          }

          const products = row.querySelectorAll('td')
          const productsArray = Array.from(products).map((product) => product.textContent)
          if (products.length) {
            tableText += `- ${productsArray[0]}: ${productsArray[1]}\n`
          }
        })
        tableText += '\n' // Add extra line break between tables if there are multiple tables
      })
    }
    return tableText
  }

  useEffect(() => {
    setTableText(getTextFromTable())
  }, [])


  const copyTableToClipboard = () => {
    if (tableText) {
      navigator.clipboard
        .writeText(tableText)
        .then(() => { 
          setCopied(true)
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
        })
    }
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <section className="summary-box">
      <div className="tables-box">
        {productsForClients.map(({ name, products }) => (
          <table className="summary-ticket-table" key={name}>
            <SummaryTableHead clientName={name} products={products} ticketWithFindings={ticketWithFindins} />
            <tbody>
              {products.map((p: TicketItems) => (
                <tr>
                  <td>{`${p.product_description}`}</td>
                  <td>{`${p.unit_price.toFixed(2)}€`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
      <div className="buttons-box">
        <div className="whatsapp-copy">
          <div onClick={copyTableToClipboard}>
            {!copied ? (
              <img src={imgs.clipboard} alt="clipboard" className="clipboard" />
            ) : (
              <span>
                <FormattedMessage id="copied" />
              </span>
            )}
            <WhatsappShareButton url={tableText} onClick={() => {}}>
              <WhatsappIcon size={42} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
        <button className="confirm-btn" onClick={() => setShowSummary(false)}>
          <FormattedMessage id="back-to-share" />
        </button>
      </div>
    </section>
  )
}

const SummaryTicketClients: React.FC<SummaryTicketClientsProps> = ({ setShowSummary }) => {
  return (
    <BaseModal headerText="ticket-summary">
      <SummaryTicketClientsContent setShowSummary={setShowSummary} />
    </BaseModal>
  )
}
export default SummaryTicketClients
